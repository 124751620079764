import { useEffect, useState } from "react";

const useOnScreen = (
  ref,
  options = { root: null, rootMargin: "0px", threshold: 0.1 }
) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    if (!ref?.current || typeof IntersectionObserver !== "function") {
      setIntersecting(true); // Default to true if IntersectionObserver is not available
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting),
      options
    );

    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [ref, options]);

  return isIntersecting;
};

export default useOnScreen;

// https://stackoverflow.com/questions/45514676/react-check-if-element-is-visible-in-dom
// Usage
// const DummyComponent = () => {

//   const ref = useRef()
//   const isVisible = useOnScreen(ref)

//   return <div ref={ref}>{isVisible && `Yep, I'm on screen`}</div>
// }
