import React from "react";
import Cart from "views/ExpiryLinks/Detail/components/Cart/Cart";
import UrlExpired from "views/ExpiryLinks/Detail/components/UrlExpired";

// utils
import ExpiryLinkDetail from "views/ExpiryLinks/Detail/Detail";

// const defaultAccess = isAdmin || isSuperAdmin;

export const primaryMenuRoutes = [
  {
    access: true,
    title: "Cart",
    path: "cart",
    component: () => <Cart />,
  },
  {
    access: true,
    title: "Expired",
    path: "expired",
    component: () => <UrlExpired />,
  },
  {
    access: true,
    title: "Home",
    path: "",
    component: () => <ExpiryLinkDetail />,
  },
];

export const secondaryMenuRoutes = [
  // {
  //   access: defaultAccess,
  //   title: "Vendors",
  //   path: enums.models["vendor-portal"].users + "/:code",
  //   component: () => (defaultAccess ? <VendorDetail /> : <HomePage />),
  // },
];
