import React, { useEffect, useState } from "react";
import {
  Dialog,
  IconButton,
  Button,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  useMediaQuery,
  Drawer,
  useTheme,
} from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import CloseIcon from "@mui/icons-material/Close";
import {
  copyToClipboard,
  formatIndianNumber,
  imageExpired,
  oopsNotFound,
  roundOff,
} from "@ui/Utils/helper";
import { getSingleRecord } from "@ui/ComponentUtils/blueprintAPIs";
import enums from "helpers/enums";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import DownloadIcon from "@mui/icons-material/Download";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import MyHoverIcons from "@ui/assets/commonIcons/customIcons/MyHoverIcons";
import commonEnums from "@ui/Utils/commonEnums";

export const FullscreenPreview = ({ open, onClose, imageSrc }) => (
  <Dialog
    open={open}
    onClose={onClose}
    fullScreen
    PaperProps={{
      sx: {
        backgroundColor: "rgba(0, 0, 0, 0.9)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 100000000,
      },
    }}
  >
    <IconButton
      onClick={onClose}
      style={{
        position: "absolute",
        top: 16,
        right: 16,
        color: "#fff",
        backgroundColor: "#c7c7c7",
        zIndex: 4,
      }}
    >
      <CloseIcon />
    </IconButton>

    <img
      src={imageSrc || oopsNotFound}
      alt="Preview"
      style={{ width: "90%", height: "100%", objectFit: "contain" }}
      onContextMenu={(e) => e.preventDefault()}
      onError={(event) => {
        event.target.src = imageExpired;
      }}
    />
  </Dialog>
);

const MyDetailDrawer = ({ open, onClose, product, isVendor = false }) => {
  const [additionalMedia, setAdditionalMedia] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [currentPreviewImage, setCurrentPreviewImage] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Breakpoint for small screens

  const componentStyles = {
    label: {
      textAlign: "left",
      color: "#333",
      fontSize: isMobile ? "12px" : "14px",
      fontFamily: "Lato",
      fontWeight: 400,
      color: "grey",
    },
    value: {
      fontFamily: "Lato",
      textAlign: "left",
      color: "#555",
      fontWeight: 400,
      fontWeight: 500,
      fontSize: isMobile ? "14px" : "16px",
      color: commonEnums.colorTheme.secondary,
    },
    goldFields: { paddingTop: "32px" },
    goldFields: { paddingTop: "12px" },
  };

  const handleImageClick = (src) => {
    setCurrentPreviewImage(src);
    setPreviewOpen(true);
  };

  useEffect(() => {
    (async () => {
      try {
        if (product.styleNo) {
          const mediaData = await getSingleRecord(
            enums.models["data-engine"].additionalMedia,
            {
              styleNo: product.styleNo,
              filterColumns: { media: 1 },
            }
          );
          setAdditionalMedia(mediaData?.media || []);
        }
      } catch (err) {
        console.error(err);
      }
    })();
  }, [product]);

  const handleDownload = (file) => {
    const link = document.createElement("a");
    link.href = file.signedUrl;
    link.download = file.fileName;
    link.click();
  };

  const isImageFile = (fileType) =>
    ["image/jpeg", "image/png", "image/gif", "image/webp"].includes(fileType);

  const isVideoFile = (fileType) =>
    [
      "video/mp4",
      "video/webm",
      "video/ogg",
      "video/quicktime",
      "video/x-msvideo",
      "video/mpeg",
      "video/x-matroska",
      "video/x-flv",
      "video/3gpp",
      "video/x-ms-wmv",
    ].includes(fileType);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: isMobile ? "100%" : "80%", // Full screen on mobile, 80% on desktop
          padding: isMobile ? 1 : 2, // Adjust padding for mobile
          backgroundColor: "#fff",

          height: "90vh", // Adjust height for linkit bottom bar
          transition: "transform 0.5s ease-in-out", // Custom transition for smoothness
        },
      }}
      ModalProps={{
        keepMounted: true, // Keeps the modal in the DOM for smoother transitions
      }}
    >
      {/* Drawer Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "16px 24px",
        }}
      >
        <Typography variant="h6" sx={{ color: "#075985" }}>
          {"Details"}
        </Typography>
        <IconButton edge="end" color="inherit" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Drawer Content */}
      <Box sx={{ padding: "16px 24px", overflowY: "auto" }}>
        <Grid container spacing={3}>
          {/* Main Product Details */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",

                alignItems: "center",
              }}
            >
              <Carousel
                showThumbs={false}
                showStatus={false}
                infiniteLoop
                autoPlay={false}
                interval={3000}
                stopOnHover
                useKeyboardArrows
                emulateTouch
              >
                <div
                  style={{ position: "relative", height: "400px" }}
                  onClick={() =>
                    handleImageClick(
                      product.defaultImage?.signedUrl || "placeholder.jpg"
                    )
                  }
                >
                  <img
                    src={product.defaultImage?.signedUrl || oopsNotFound}
                    onError={(event) => {
                      event.target.src = imageExpired;
                    }}
                    alt={product.styleNo}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      borderRadius: "8px",
                      userSelect: "none",
                    }}
                    onContextMenu={(e) => e.preventDefault()}
                  />
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: 16,
                      right: 16,
                      color: "#fff",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                      },
                    }}
                  >
                    <FullscreenIcon />
                  </IconButton>
                </div>
                {additionalMedia.map((media, index) =>
                  isImageFile(media.fileType) ? (
                    <div
                      key={index}
                      style={{ position: "relative", height: "400px" }}
                      onClick={() => handleImageClick(media.signedUrl)}
                    >
                      <img
                        src={media.signedUrl}
                        onError={(event) => {
                          event.target.src = imageExpired;
                        }}
                        alt={media.fileName}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                          borderRadius: "8px",
                        }}
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </div>
                  ) : isVideoFile(media.fileType) ? (
                    <div key={index} style={{ height: "400px" }}>
                      <video
                        controls
                        controlsList="nodownload"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "8px",
                        }}
                        onContextMenu={(e) => e.preventDefault()}
                      >
                        <source src={media.signedUrl} type={media.fileType} />
                      </video>
                    </div>
                  ) : (
                    <Button
                      key={index}
                      variant="contained"
                      color="primary"
                      startIcon={<DownloadIcon />}
                      onClick={() => handleDownload(media)}
                    >
                      Download {media.fileName}
                    </Button>
                  )
                )}
              </Carousel>
            </Box>
            {isVendor ? null : (
              <Typography
                variant="h6"
                style={{
                  // marginTop: 2
                  textAlign: "center",
                  fontSize: "14px",
                  color: "green",
                }}
              >
                ₹{" "}
                {product.jewelPrice
                  ? formatIndianNumber(roundOff(product.jewelPrice))
                  : "..."}
              </Typography>
            )}
          </Grid>

          {/* Gold Details */}
          <Grid item xs={12} md={6}>
            <Card
              style={{
                background: "linear-gradient(180deg, #F0F9FF 0%, #FFFFFF 100%)",
                borderRadius: 5,
              }}
            >
              <CardContent>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container style={componentStyles.goldFields}>
                      <Grid item xs={4}>
                        <span style={componentStyles.label}>Design:</span>
                      </Grid>
                      <Grid item xs={6}>
                        <span style={componentStyles.value}>
                          {product.styleNo}
                        </span>
                      </Grid>
                      <Grid item xs={2} style={{ maxHeight: "20px" }}>
                        <MyHoverIcons
                          muiIcon={<FileCopyOutlinedIcon />}
                          muiIconHover={<FileCopyIcon />}
                          tooltip={"> copy"}
                          onClick={() => {
                            copyToClipboard(product.styleNo);
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container style={componentStyles.goldFields}>
                      <Grid item xs={4}>
                        <span style={componentStyles.label}>SKU:</span>
                      </Grid>
                      <Grid item xs={8}>
                        <span style={componentStyles.value}>{product.sku}</span>
                      </Grid>
                    </Grid>

                    {product.category?.name ? (
                      <Grid container style={componentStyles.goldFields}>
                        <Grid item xs={4}>
                          <span style={componentStyles.label}>Category:</span>
                        </Grid>
                        <Grid item xs={8}>
                          <span style={componentStyles.value}>
                            {product.category?.name}
                          </span>
                        </Grid>
                      </Grid>
                    ) : null}

                    {product.subCategory?.name ? (
                      <Grid container style={componentStyles.goldFields}>
                        <Grid item xs={4}>
                          <span style={componentStyles.label}>
                            Sub Category:
                          </span>
                        </Grid>
                        <Grid item xs={8}>
                          <span style={componentStyles.value}>
                            {product.subCategory?.name}
                          </span>
                        </Grid>
                      </Grid>
                    ) : null}

                    {product.collectionLine?.name ? (
                      <Grid container style={componentStyles.goldFields}>
                        <Grid item xs={4}>
                          <span style={componentStyles.label}>
                            Collection Line:
                          </span>
                        </Grid>
                        <Grid item xs={8}>
                          <span style={componentStyles.value}>
                            {product.collectionLine?.name}
                          </span>
                        </Grid>
                      </Grid>
                    ) : null}

                    <Grid container style={componentStyles.goldFields}>
                      <Grid item xs={4}>
                        <span style={componentStyles.label}>Net Weight:</span>
                      </Grid>
                      <Grid item xs={8}>
                        <span style={componentStyles.value}>
                          {roundOff(
                            product.jewelPriceBreakup?.netWeight ||
                              product.netWeight,
                            { weight: true }
                          )}{" "}
                          g
                        </span>
                      </Grid>
                    </Grid>
                    <Grid container style={componentStyles.goldFields}>
                      <Grid item xs={4}>
                        <span style={componentStyles.label}>Gross Weight:</span>
                      </Grid>
                      <Grid item xs={8}>
                        <span style={componentStyles.value}>
                          {roundOff(product.grossWeight, { weight: true })} g
                        </span>
                      </Grid>
                    </Grid>
                    {product.purity?.value && (
                      <Grid container style={componentStyles.goldFields}>
                        <Grid item xs={4}>
                          <span style={componentStyles.label}>Purity:</span>
                        </Grid>
                        <Grid item xs={8}>
                          <span style={componentStyles.value}>
                            {product.purity?.value} KT
                          </span>
                        </Grid>
                      </Grid>
                    )}

                    {product.color ? (
                      <Grid container style={componentStyles.goldFields}>
                        <Grid item xs={4}>
                          <span style={componentStyles.label}>Color:</span>
                        </Grid>
                        <Grid item xs={8}>
                          <span style={componentStyles.value}>
                            {product.color}
                          </span>
                        </Grid>
                      </Grid>
                    ) : null}

                    {product.screw ? (
                      <Grid container style={componentStyles.goldFields}>
                        <Grid item xs={4}>
                          <span style={componentStyles.label}>Screw:</span>
                        </Grid>
                        <Grid item xs={8}>
                          <span style={componentStyles.value}>
                            {product.screw}
                          </span>
                        </Grid>
                      </Grid>
                    ) : null}

                    {product.setting ? (
                      <Grid container style={componentStyles.goldFields}>
                        <Grid item xs={4}>
                          <span style={componentStyles.label}>Setting:</span>
                        </Grid>
                        <Grid item xs={8}>
                          <span style={componentStyles.value}>
                            {product.setting}
                          </span>
                        </Grid>
                      </Grid>
                    ) : null}

                    {product.specificEngraving ? (
                      <Grid container style={componentStyles.goldFields}>
                        <Grid item xs={4}>
                          <span style={componentStyles.label}>
                            Specific Engraving:
                          </span>
                        </Grid>
                        <Grid item xs={8}>
                          <span style={componentStyles.value}>
                            {product.specificEngraving}
                          </span>
                        </Grid>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Diamond Details */}
          {product.diamondDetails?.length > 0 && (
            <Grid item xs={12}>
              <Card
                style={{
                  background:
                    "linear-gradient(180deg, #F0F9FF 0%, #FFFFFF 100%)",
                  borderRadius: 5,
                }}
              >
                <CardContent>
                  <pre>Diamonds</pre>
                  <Grid container spacing={2}>
                    {product.diamondDetails.map((diamond, index) => (
                      <Grid item xs={12} sm={6} key={index}>
                        💎 {index + 1}
                        <Box
                          sx={{
                            padding: 1,
                            backgroundColor: "#fff",
                            borderRadius: 5,
                            boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          <Grid container style={componentStyles.diamondFields}>
                            <Grid item xs={4}>
                              <span style={componentStyles.label}>
                                Quantity:
                              </span>
                            </Grid>
                            <Grid item xs={8}>
                              <span style={componentStyles.value}>
                                {diamond.diamondQuantity}
                              </span>
                            </Grid>
                          </Grid>
                          <Grid container style={componentStyles.diamondFields}>
                            <Grid item xs={4}>
                              <span style={componentStyles.label}>
                                Total Carat:
                              </span>
                            </Grid>
                            <Grid item xs={8}>
                              <span style={componentStyles.value}>
                                {roundOff(diamond.totalCarat, {
                                  weight: true,
                                  isDiamond: true,
                                })}
                              </span>
                            </Grid>
                          </Grid>
                          {diamond.shape && (
                            <Grid
                              container
                              style={componentStyles.diamondFields}
                            >
                              <Grid item xs={4}>
                                <span style={componentStyles.label}>
                                  Shape:
                                </span>
                              </Grid>
                              <Grid item xs={8}>
                                <span style={componentStyles.value}>
                                  {diamond.shape}
                                </span>
                              </Grid>
                            </Grid>
                          )}
                          {diamond.cut && (
                            <Grid
                              container
                              style={componentStyles.diamondFields}
                            >
                              <Grid item xs={4}>
                                <span style={componentStyles.label}>Cut:</span>
                              </Grid>
                              <Grid item xs={8}>
                                <span style={componentStyles.value}>
                                  {diamond.cut}
                                </span>
                              </Grid>
                            </Grid>
                          )}

                          {diamond.color && (
                            <Grid
                              container
                              style={componentStyles.diamondFields}
                            >
                              <Grid item xs={4}>
                                <span style={componentStyles.label}>
                                  Color:
                                </span>
                              </Grid>
                              <Grid item xs={8}>
                                <span style={componentStyles.value}>
                                  {diamond.color}
                                </span>
                              </Grid>
                            </Grid>
                          )}

                          {diamond.clarity && (
                            <Grid
                              container
                              style={componentStyles.diamondFields}
                            >
                              <Grid item xs={4}>
                                <span style={componentStyles.label}>
                                  Clarity:
                                </span>
                              </Grid>
                              <Grid item xs={8}>
                                <span style={componentStyles.value}>
                                  {diamond.clarity}
                                </span>
                              </Grid>
                            </Grid>
                          )}

                          {diamond.polish && (
                            <Grid
                              container
                              style={componentStyles.diamondFields}
                            >
                              <Grid item xs={4}>
                                <span style={componentStyles.label}>
                                  Polish:
                                </span>
                              </Grid>
                              <Grid item xs={8}>
                                <span style={componentStyles.value}>
                                  {diamond.polish}
                                </span>
                              </Grid>
                            </Grid>
                          )}

                          {diamond.symmetry && (
                            <Grid
                              container
                              style={componentStyles.diamondFields}
                            >
                              <Grid item xs={4}>
                                <span style={componentStyles.label}>
                                  Symmetry:
                                </span>
                              </Grid>
                              <Grid item xs={8}>
                                <span style={componentStyles.value}>
                                  {diamond.symmetry}
                                </span>
                              </Grid>
                            </Grid>
                          )}

                          {diamond.gradingLab && (
                            <Grid
                              container
                              style={componentStyles.diamondFields}
                            >
                              <Grid item xs={4}>
                                <span style={componentStyles.label}>
                                  Grading Lab:
                                </span>
                              </Grid>
                              <Grid item xs={8}>
                                <span style={componentStyles.value}>
                                  {diamond.gradingLab}
                                </span>
                              </Grid>
                            </Grid>
                          )}

                          {diamond.fluorescenceIntensity && (
                            <Grid
                              container
                              style={componentStyles.diamondFields}
                            >
                              <Grid item xs={4}>
                                <span style={componentStyles.label}>
                                  Fluorescence Intensity:
                                </span>
                              </Grid>
                              <Grid item xs={8}>
                                <span style={componentStyles.value}>
                                  {diamond.fluorescenceIntensity}
                                </span>
                              </Grid>
                            </Grid>
                          )}

                          {diamond.isSolitaire ? (
                            <Grid
                              container
                              style={componentStyles.diamondFields}
                            >
                              <Grid item xs={4}>
                                <span style={componentStyles.label}>
                                  Solitaire Size:
                                </span>
                              </Grid>
                              <Grid item xs={8}>
                                <span style={componentStyles.value}>
                                  {diamond.solitaireSize?.length || 0} x{" "}
                                  {diamond.solitaireSize?.width || 0} mm
                                </span>
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid
                              container
                              style={componentStyles.diamondFields}
                            >
                              <Grid item xs={4}>
                                <span style={componentStyles.label}>
                                  Sieve Size:
                                </span>
                              </Grid>
                              <Grid item xs={8}>
                                <span style={componentStyles.value}>
                                  {diamond.sieveSize?.length || 0} x{" "}
                                  {diamond.sieveSize?.width || 0} mm
                                </span>
                              </Grid>
                            </Grid>
                          )}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </Box>

      {/* Fullscreen image preview dialog */}
      {currentPreviewImage && (
        <FullscreenPreview
          open={previewOpen}
          onClose={() => setPreviewOpen(false)}
          imageSrc={currentPreviewImage}
        />
      )}
    </Drawer>
  );
};

export default MyDetailDrawer;
