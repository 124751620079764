import { isDivisionControlled, isVendor } from "./helper";

export const attachJewelPrice = (
  products = [],
  customerId = null,
  {
    globalGoldRatePerGram = 0,
    goldPricingList = [],
    diamondPricingList = [],
  } = {} //masterData
) => {
  if (isVendor) {
    return products;
  }

  customerId = customerId; //  Customer user ID required to calculate Price with their discount

  try {
    if (goldPricingList.length) {
      products = products.map((prod) => {
        const goldPricingObj = goldPricingList.find((goldPricing) => {
          return (
            !isDivisionControlled ||
            (isDivisionControlled &&
              goldPricing.division._id.toString() ===
                prod.division._id.toString())
          );
        });

        if (prod && goldPricingObj) {
          /* Gold Price = (((globalGoldRatePerTroyOunce / 31.1) * Purity) + Labor ) * netWeight;
             Diamond Price = totalCarat / 5 and find this carat range in diamondPricing and check if any discount is available for the req.user._id and apply
          */
          //    Deciding the Priority of choosing Labor Price with Discount for a user

          let matchedStoneLess = {},
            applicableLessWeight = 0,
            matchedPriceLess = {},
            applicableTotalExtrasPrice = 0,
            applicablePurityWithWastage = prod.purity.percent,
            applicableGoldLaborPrice = 0;

          const goldLaborPriceObj = getGoldLaborPrice(
            prod,
            goldPricingObj,
            customerId
          );

          applicablePurityWithWastage += goldLaborPriceObj.wastage || 0;
          applicableGoldLaborPrice = goldLaborPriceObj.goldLaborPrice || 0;

          // calculate applicableLessWeight according to discount group they belong to include or not include any extra for Less Weight
          // Finding matched stone Less Weight discountGroup this.req.user is in
          (goldPricingObj.stoneLessConfigs || []).find(
            (stoneLessSlab, stoneLessSlabIndex) => {
              if (stoneLessSlabIndex === 0) {
                // as Default Stone Less Config is always stored in 0th index
                matchedStoneLess = stoneLessSlab;
              } else {
                if (
                  (stoneLessSlab.users || []).find(
                    (user) => user._id?.toString() == customerId
                  )
                ) {
                  matchedStoneLess = stoneLessSlab;
                  return true;
                }
              }
            }
          );

          // Calculating Applicable Less Weight
          Object.keys(matchedStoneLess?.weightField || {}).map(
            (weightFieldName) => {
              if (
                matchedStoneLess.weightField[weightFieldName]
                  ?.includeForLessWeight
              ) {
                let applyingWeight = prod[weightFieldName] || 0; // dude, diamondFields is also checked here.. fix it
                if (matchedStoneLess.weightField[weightFieldName].inputInCts) {
                  applyingWeight = applyingWeight * 0.2; // converting to grams
                }

                applicableLessWeight += applyingWeight;
              }
              // Do this for Mobile apps after making Printing Station API separately to Export Excel which gets used in Excel import and it will have all data of the product
              //  else {
              //   delete prod[weightFieldName];
              // }
            }
          );

          // Finding matched price discountGroup this.req.user is in
          (goldPricingObj.priceLessConfigs || []).find(
            (priceLessSlab, priceLessSlabIndex) => {
              if (priceLessSlabIndex === 0) {
                // as Default Pricing is always stored in 0th index
                matchedPriceLess = priceLessSlab;
              } else {
                if (
                  (priceLessSlab.users || []).find(
                    (user) => user._id?.toString() == customerId
                  )
                ) {
                  matchedPriceLess = priceLessSlab;
                  return true;
                }
              }
            }
          );

          // Calculating Applicable Total Extras Price
          Object.keys(matchedPriceLess?.priceField || {}).map(
            (priceFieldName) => {
              const nakedPriceFieldName = priceFieldName.replace("Rate", "");

              let extraPriceCalculated;

              const overridenPrice = !isNaN(
                parseFloat(prod[nakedPriceFieldName + "Rate"])
              ) // != checks for both null and undefined... :) null == undefined is true and null === undefined is false // and new update is, parseFloat will easily tell you NaN if its invalid
                ? prod[nakedPriceFieldName + "Rate"] || 0
                : matchedPriceLess.priceField[priceFieldName].customPrice || 0;

              if (matchedPriceLess.priceField[priceFieldName]?.pricePerWeight) {
                extraPriceCalculated =
                  overridenPrice * (prod[nakedPriceFieldName + "Weight"] || 0);
              } else if (
                matchedPriceLess.priceField[priceFieldName]?.pricePerPiece
              ) {
                extraPriceCalculated =
                  overridenPrice * (prod[nakedPriceFieldName + "Pieces"] || 0);
              }

              if (
                matchedPriceLess.priceField[priceFieldName]
                  .includeForTotalExtrasPrice
              ) {
                applicableTotalExtrasPrice += extraPriceCalculated;

                prod[priceFieldName.replace("Rate", "Price")] = // to send the caculated stone price individually in the response...
                  extraPriceCalculated;

                prod[priceFieldName] = // rateField is having variable name as priceFieldName.. so to save time, we can consider, rate and price Field is almost same, stonePrice will only be there if stoneRate field is there...
                  !isNaN(parseFloat(prod[nakedPriceFieldName + "Rate"]))
                    ? prod[nakedPriceFieldName + "Rate"]
                    : !isNaN(
                        matchedPriceLess.priceField[priceFieldName].customPrice
                      )
                    ? matchedPriceLess.priceField[priceFieldName].customPrice
                    : undefined; // to save the rate at the time of this product price seen in frontend and to have them in their orders collection...
              }
            }
          );

          const diamondDetailsPrices = [];

          let diamondPrice = 0;
          (prod.diamondDetails || []).map((diamond) => {
            if (
              matchedStoneLess.weightField.diamondFields?.includeForLessWeight
            ) {
              applicableLessWeight += (diamond.totalCarat || 0) * 0.2;
            }

            const matchedDiamondPrice = diamondPricingList.find(
              (diamondPrice) => {
                if (
                  !isDivisionControlled ||
                  (isDivisionControlled &&
                    diamondPrice.division._id.toString() ===
                      prod.division._id.toString())
                ) {
                  const isShapeMatched =
                      diamond.shape === "Round"
                        ? diamondPrice.diamondType === "Round"
                        : diamondPrice.diamondType === "Fancy",
                    caratPerQuantity = Number(
                      (
                        (diamond.totalCarat || 0) /
                        (diamond.diamondQuantity || 1)
                      ).toFixed(3)
                    );

                  if (
                    isShapeMatched &&
                    caratPerQuantity >= diamondPrice.minCarat &&
                    caratPerQuantity <= diamondPrice.maxCarat
                  ) {
                    return true;
                  }
                }
              }
            );

            let currentDiamondPrice = 0;
            const match = (matchedDiamondPrice?.discountGroup || []).find(
              (group) =>
                (group.users || []).find(
                  (user) => user?._id?.toString() === customerId
                )
            );
            const diamondRate = match
              ? match?.customPrice || 0 // if the user is found in discount dropdown, then the price given there will be used to calc
              : matchedDiamondPrice?.ratePerCarat || 0; // else, default ratePerCarat is taken if they are not

            if (matchedDiamondPrice) {
              currentDiamondPrice = diamondRate * (diamond.totalCarat || 0);

              diamondPrice += currentDiamondPrice;
            }

            diamondDetailsPrices.push({
              price: Number(currentDiamondPrice.toFixed(2)),
              rate: diamondRate,
            });
          });

          const weightFieldAsPerCustomer =
              prod.grossWeight - applicableLessWeight,
            goldPrice = Number(
              (
                (globalGoldRatePerGram * (applicablePurityWithWastage || 0) +
                  (applicableGoldLaborPrice || 0)) *
                weightFieldAsPerCustomer
              )?.toFixed(2)
            );

          return {
            ...prod,
            jewelPrice: Number(
              (diamondPrice + goldPrice + applicableTotalExtrasPrice).toFixed(2)
            ),
            jewelPriceBreakup: {
              gold: goldPrice,

              diamonds: diamondDetailsPrices,

              totalExtrasPrice: Number(applicableTotalExtrasPrice.toFixed(2)),

              liveGoldPrice: globalGoldRatePerGram,
              livePureMetalPrice: Number(
                (
                  globalGoldRatePerGram *
                  weightFieldAsPerCustomer *
                  (applicablePurityWithWastage || 0)
                ).toFixed(2)
              ),

              netWeightGoldLaborPrice: Number(
                (
                  Number(applicableGoldLaborPrice) * weightFieldAsPerCustomer
                ).toFixed(2)
              ),

              goldLaborPrice: Number(applicableGoldLaborPrice.toFixed(2)),
              appliedWastage: goldLaborPriceObj.wastage, // frontend validation for not more than 3 decimals helped to not give here.. :P
              appliedLessWeight: Number(applicableLessWeight.toFixed(3)),
              appliedNetWeight: Number(weightFieldAsPerCustomer.toFixed(3)),
            },
          };
        } else {
          return prod;
        }
      });
    } else {
      console.log("Metal Pricing master data not found...");
    }

    return products;
  } catch (err) {
    console.log("Failed while calculating Jewelry Price: ", err);
    // throw err; // let's not throw.. let products go without jewel price if anything goes wrong here...
    return products;
  }
};

const getGoldLaborPrice = (prod, goldPricingObj = {}, customerId) => {
  goldPricingObj = goldPricingObj || {};

  // Priority: 1. Vendor Code 2. Sub Category 3. Category 4. Collection Line
  let goldLaborPrice = 0,
    wastage = 0,
    discountFoundFlag = false;

  // 1. Vendor Code Discount Check
  if (prod.vendorCode && goldPricingObj.vendorCode?.[prod.vendorCode]) {
    const discountGroup = (
      goldPricingObj.vendorCode[prod.vendorCode].discountGroup || []
    ).find((group) =>
      (group.users || []).find((user) => user?._id?.toString() === customerId)
    );

    if (discountGroup) {
      discountFoundFlag = true;
      goldLaborPrice = discountGroup.customPrice;
      wastage = discountGroup.customWastage;
    }
  }

  // 2. SubCategory Discount Check
  if (
    !discountFoundFlag &&
    prod.subCategory?._id?.toString() &&
    goldPricingObj.subCategory?.[prod.subCategory._id?.toString()]
  ) {
    const discountGroup = (
      goldPricingObj.subCategory[prod.subCategory._id?.toString()]
        .discountGroup || []
    ).find((group) =>
      (group.users || []).find((user) => user?._id?.toString() === customerId)
    );

    if (discountGroup) {
      discountFoundFlag = true;
      goldLaborPrice = discountGroup.customPrice;
      wastage = discountGroup.customWastage;
    }
  }

  // 3. Category Discount Check
  if (
    !discountFoundFlag &&
    prod.category?._id &&
    goldPricingObj.category?.[prod.category._id?.toString()]
  ) {
    const discountGroup = (
      goldPricingObj.category[prod.category._id?.toString()].discountGroup || []
    ).find((group) =>
      (group.users || []).find((user) => user?._id?.toString() === customerId)
    );

    if (discountGroup) {
      discountFoundFlag = true;
      goldLaborPrice = discountGroup.customPrice;
      wastage = discountGroup.customWastage;
    }
  }

  // 4. Collection Line Discount Check
  if (
    !discountFoundFlag &&
    prod.collectionLine?._id &&
    goldPricingObj.collectionLine?.[prod.collectionLine._id?.toString()]
  ) {
    const discountGroup = (
      goldPricingObj.collectionLine[prod.collectionLine._id?.toString()]
        .discountGroup || []
    ).find((group) =>
      (group.users || []).find((user) => user?._id?.toString() === customerId)
    );

    if (discountGroup) {
      discountFoundFlag = true;
      goldLaborPrice = discountGroup.customPrice;
      wastage = discountGroup.customWastage;
    }
  }

  //   After checking if this.user has any discount for any of the field in Prioriry, choose that discount's laborPrice
  // if user doesn't have any discount, then take labor price of the field which has laborPrice value available according to same Priority

  if (!discountFoundFlag) {
    if (
      // 1. Vendor Code Labor Price
      prod.vendorCode &&
      (!isNaN(
        parseFloat(goldPricingObj.vendorCode?.[prod.vendorCode]?.laborPrice)
      ) ||
        !isNaN(
          parseFloat(goldPricingObj.vendorCode?.[prod.vendorCode]?.wastage)
        ))
    ) {
      goldLaborPrice = goldPricingObj.vendorCode?.[prod.vendorCode]?.laborPrice;
      wastage = goldPricingObj.vendorCode?.[prod.vendorCode]?.wastage;
    } else if (
      // 2. Sub Category Labor Price
      prod.subCategory?._id &&
      (!isNaN(
        parseFloat(
          goldPricingObj.subCategory?.[prod.subCategory._id?.toString()]
            ?.laborPrice
        )
      ) ||
        !isNaN(
          parseFloat(
            goldPricingObj.subCategory?.[prod.subCategory._id?.toString()]
              ?.wastage
          )
        ))
    ) {
      goldLaborPrice =
        goldPricingObj.subCategory?.[prod.subCategory._id?.toString()]
          ?.laborPrice;
      wastage =
        goldPricingObj.subCategory?.[prod.subCategory._id?.toString()]?.wastage;
    } else if (
      prod.category?._id?.toString() &&
      (!isNaN(
        parseFloat(
          goldPricingObj.category?.[prod.category._id?.toString()]?.laborPrice
        )
      ) ||
        !isNaN(
          parseFloat(
            goldPricingObj.category?.[prod.category._id?.toString()]?.wastage
          )
        ))
    ) {
      // 3. Category Labor Price
      goldLaborPrice =
        goldPricingObj.category?.[prod.category._id?.toString()]?.laborPrice;
      wastage =
        goldPricingObj.category?.[prod.category._id?.toString()]?.wastage;
    } else if (
      prod.collectionLine &&
      (!isNaN(
        parseFloat(
          goldPricingObj.collectionLine?.[prod.collectionLine._id?.toString()]
            ?.laborPrice
        )
      ) ||
        !isNaN(
          parseFloat(
            goldPricingObj.collectionLine?.[prod.collectionLine._id?.toString()]
              ?.wastage
          )
        ))
    ) {
      // 4. Collection Line Labor Price
      goldLaborPrice =
        goldPricingObj.collectionLine?.[prod.collectionLine._id?.toString()]
          ?.laborPrice;
      wastage =
        goldPricingObj.collectionLine?.[prod.collectionLine._id?.toString()]
          ?.wastage;
    }
  }
  return {
    goldLaborPrice: goldLaborPrice,
    wastage: wastage,
  };
};
