const enums = {
  colorTheme: {
    primary: "#1d9bc2", // blue
    secondary: "#075985",
    secondaryLight: "#EFF6FF",
  },

  stockStatus: {
    staged: "Staged",
    inventory: "In Inventory",
    approval: "In Approval",
    issued: "Issued",
    unAvailable: "Unavailable",
  },

  keywords: {
    selectAll: "Select All",
    empty: "NONE",
  },

  orderStatus: {
    // Keep the orderStatus in same order one after other ->
    inReview: "In review",
    accepted: "Accepted",
    making: "Making",
    dispatched: "Dispatched",
    delivered: "Delivered",
    repair: "Repair",
    returned: "Returned",
    canceled: "Canceled",
  },

  roles: {
    superAdmin: "Super Admin",
    admin: "Admin",
    manager: "Manager",
    branch: "Branch",
    zoneManager: "Zone Manager",
    inventoryManager: "Inventory Manager",
    salesHead: "Sales Head",
    salesPerson: "Sales Person",
    vendor: "Vendor",
    customer: "Customer",
  },

  fieldTypes: {
    textField: "textField",
    dropdown: "dropdown",
    asyncDropdown: "asyncDropdown",
    upload: "upload",
    featureCheckbox: "featureCheckbox",
  },
  models: {
    iam: {
      clients: "iam/clients",
      users: "iam/users",
    },
    "data-engine": {
      additionalMedia: "data-engine/additionalMedia",
      products: "data-engine/products",
      purity: "data-engine/purity",
      collectionLines: "data-engine/collectionLines",
      category: "data-engine/category",
      subCategory: "data-engine/subCategory",
      users: "data-engine/users", // All Customers from all apps will be here
    },

    products: "data-engine/products",
    purity: "data-engine/purity",
    collectionLines: "data-engine/collectionLines",
    category: "data-engine/category",
    subCategory: "data-engine/subCategory",

    linkItOrders: "e-creatives/linkItOrders",
    linkItCarts: "e-creatives/linkItCarts",
    expiryLinks: "e-creatives/expiryLinks",
  },

  dataTypes: {
    string: "string",
    number: "number",
    object: "object",
    array: "array",
  },

  sessionStorage: {
    allowedCatalogues: "allowedCatalogues",
    division: "division",
    lastCriteria: "lastCriteria",
  },
};

export default enums;
