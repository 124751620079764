import React, { useEffect, useState } from "react";
import { Drawer, Grid, Button, Paper, Typography, Badge } from "@mui/material";
import { TextField } from "@material-ui/core/index";
import enums from "helpers/enums";
// import GppBadIcon from "@mui/icons-material/GppBad";
// import GppBadOutlinedIcon from "@mui/icons-material/GppBadOutlined";
// import MyHoverIcons from "@ui/assets/commonIcons/customIcons/MyHoverIcons";
import _ from "lodash";
import { isObjectDirty, regexPurify } from "@ui/Utils/helper";
import { countActiveFilters } from "./AppBarComponent";
import "./robot_drawer.css";
import { MyAutocomplete } from "@ui/MuiComponents/index";
import { useDispatch, useSelector } from "react-redux";
import { clearOGfilter, setOGfilter } from "redux/linkitSlices/ogFilterSlice";
import { fetchProducts, setPage } from "redux/linkitSlices/mainSlice";

export const sessionStorageConstants = {
  productsCriteria: `${enums.models.expiryLinks}.productsCriteria`,
};

export const getLinkItProductsMongooseCriteria = (filterCriteria) => {
  const criteria = {};
  filterCriteria = _.cloneDeep(filterCriteria);

  try {
    if (filterCriteria.grossWeight?.from?.toString()?.trim()) {
      criteria["grossWeight"] = {
        $gte: Number(filterCriteria?.grossWeight?.from),
      };
    }
    if (filterCriteria.grossWeight?.to?.toString()?.trim()) {
      criteria["grossWeight"] = {
        ...criteria["grossWeight"],
        $lte: Number(filterCriteria.grossWeight?.to),
      };
    }
    if (filterCriteria.netWeight?.from?.toString()?.trim()) {
      criteria["netWeight"] = {
        $gte: Number(filterCriteria?.netWeight?.from),
      };
    }
    if (filterCriteria.netWeight?.to?.toString()?.trim()) {
      criteria["netWeight"] = {
        ...criteria["netWeight"],
        $lte: Number(filterCriteria.netWeight?.to),
      };
    }
    if (filterCriteria.totalDiamondWeight?.from?.toString()?.trim()) {
      criteria["totalDiamondWeight"] = {
        $gte: Number(filterCriteria?.totalDiamondWeight?.from),
      };
    }
    if (filterCriteria.totalDiamondWeight?.to?.toString()?.trim()) {
      criteria["totalDiamondWeight"] = {
        ...criteria["totalDiamondWeight"],
        $lte: Number(filterCriteria.totalDiamondWeight?.to),
      };
    }

    if (filterCriteria.category?.length) {
      criteria["category.name"] = {
        $in: filterCriteria.category.map((item) => item.name),
      };
    }
    if (filterCriteria.subCategory?.length) {
      criteria["subCategory.name"] = {
        $in: filterCriteria.subCategory.map((item) => item.name),
      };
    }
    if (filterCriteria.collectionLine?.length) {
      criteria["collectionLine.name"] = {
        $in: filterCriteria.collectionLine.map((item) => item.name),
      };
    }
    if (filterCriteria.purity?.length) {
      criteria["purity.percent"] = {
        $in: filterCriteria.purity.map((item) => item.percent),
      };
    }

    if (filterCriteria.searchText?.toString().trim()) {
      const styleNoAndSkus = filterCriteria.searchText
        .split(",")
        .map((ele) => (ele?.toString().trim() ? ele?.toString().trim() : null))
        .filter((e) => e);

      if (styleNoAndSkus?.length) {
        criteria["$or"] = [
          ...styleNoAndSkus.map((val) => ({
            styleNo: {
              $regex: regexPurify(val),
              $options: "i", // Case-insensitive option
            },
          })),
          ...styleNoAndSkus.map((val) => ({
            sku: {
              $regex: regexPurify(val),
              $options: "i", // Case-insensitive option
            },
          })),
        ];
      }
    }
  } catch (err) {
    console.log(err);
  }

  return criteria;
};

const SearchFilter = ({
  // setAppliedFilterCriteria,
  masterData = {},
  showFilter,
  setShowFilter,
  puritiesMaster,
  ogFilter,
}) => {
  const dispatch = useDispatch();

  const [filterCriteria, setFilterCriteria] = useState({});

  // useEffect(() => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  //   // setOGfilter(filterCriteria);
  // }, []);

  useEffect(() => {
    if (showFilter) {
      setFilterCriteria(ogFilter);
    }

    return () => {
      setFilterCriteria({});
    };
  }, [ogFilter]);

  const handleChange = (field) => (e) => {
    setFilterCriteria({
      ..._.cloneDeep(filterCriteria),
      [field]: e.target.value,
    });
  };

  const handleRangeChange = (field, range) => (e) => {
    setFilterCriteria({
      ..._.cloneDeep(filterCriteria),
      [field]: {
        ...filterCriteria[field],
        [range]: e.target.value,
      },
    });
  };

  const handleAutocompleteChange = (field, value) => {
    setFilterCriteria({
      ..._.cloneDeep(filterCriteria),
      [field]: value,
    });
  };

  const handleSubmit = () => {
    if (Object.keys(filterCriteria).length) {
      dispatch(setOGfilter(filterCriteria));
    } else {
      dispatch(clearOGfilter());
    }

    dispatch(setPage(1));
    dispatch(
      fetchProducts({
        page: 1,
        productsCriteria: getLinkItProductsMongooseCriteria(filterCriteria),
      })
    );
    setShowFilter(false); // Close the filter drawer after applying filters
  };

  const handleReset = () => {
    setFilterCriteria({});
  };

  const FilterAction = () => {
    return (
      <Grid container>
        <Badge
          badgeContent={countActiveFilters(filterCriteria)} // Example badge count, you can set this dynamically
          color="secondary"
          overlap="rectangular" // Ensure the badge is positioned over a rectangular area
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          style={{ width: "100%" }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            onMouseEnter={(event) => {
              event.target.style.backgroundColor = "#075985"; // Swap background color on hover
              event.target.style.color = "#EFF6FF";
            }}
            onMouseLeave={(event) => {
              event.target.style.backgroundColor = "#EFF6FF"; // Original background color
              event.target.style.color = "#075985";
            }}
            style={{
              background: "#EFF6FF",
              color: "#075985",
              padding: "8px 16px",
              // width: "100%", // Ensure button takes full width of its Grid container
              width: "324px",
              height: "48px",
              textTransform: "none",
              fontSize: "1rem",
              fontWeight: "bold",
              marginTop: "16px",
            }}
          >
            APPLY
          </Button>
        </Badge>

        {isObjectDirty({}, filterCriteria) ? (
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleReset}
            style={{
              width: "324px",
              padding: "8px 16px",
              fontSize: "1rem",
              backgroundColor: "white",
              textTransform: "none",
            }}
          >
            reset
            {/* <MyHoverIcons
                muiIcon={<GppBadIcon style={componentStyles.resetIcon} />}
                muiIconHover={
                  <GppBadOutlinedIcon style={componentStyles.resetIcon} />
                }
                tooltip={"Reset"}
                onClick={handleReset}
              /> */}
          </Button>
        ) : null}
      </Grid>
    );
  };

  return (
    <Drawer
      anchor="left"
      open={showFilter}
      onClose={() => setShowFilter(false)}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          marginTop: "24px",
          width: "100%", // Default to full width for mobile
          maxWidth: "380px", // Set a maximum width for desktop
        },
        className: showFilter ? "swipe-in" : "swipe-out",
      }}
    >
      <div
        style={{
          height: "90vh", // Set the drawer height to less than 70% of the viewport height
          overflowY: "scroll", // Allow scrolling if content overflows
          padding: "16px", // Add padding for some space inside the drawer
        }}
      >
        {showFilter ? <FilterAction /> : null}

        <div style={{ width: "320px", padding: "4px" }}>
          <Paper
            style={{
              padding: 16,
              marginBottom: "40px",
              // backgroundColor: "rgba(0, 0, 0, 0.1)",
              backgroundColor: "rgba(255, 255, 255, 0.1)", // 90% transparent white background
              backdropFilter: "blur(10px)", // Apply a 10px blur effect
              WebkitBackdropFilter: "blur(10px)", // For Safari support
              color: "white",
            }}
          >
            {/* <Typography variant="h6" gutterBottom>
              Search Filters
            </Typography> */}
            {showFilter && filterCriteria && (
              <Grid container spacing={2} style={{ marginBottom: "24px" }}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="DESIGN 1, SKU1, design2, sku2"
                    value={filterCriteria.searchText || ""}
                    onChange={handleChange("searchText")}
                    // variant="outlined"
                    InputProps={{
                      style: {
                        paddingTop: "8px",
                        // paddingBottom: "8px",
                        color: "white",
                      }, // Adjust padding as needed
                    }}
                    InputLabelProps={{
                      style: {
                        color: "white", // Label color
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MyAutocomplete
                    multiple
                    options={puritiesMaster}
                    getOptionLabel={(op) =>
                      op?.value
                        ? `${op.value} KT (${(op.percent * 100).toFixed(2)}%)`
                        : ""
                    }
                    value={filterCriteria.purity || []}
                    label="Purity"
                    onChange={(_, v) => handleAutocompleteChange("purity", v)}
                    hideAction={{ successMessage: true }}
                    getOptionSelected={(opt, val) => opt?._id === val?._id}
                    InputProps={{
                      style: {
                        color: "white",
                        paddingTop: "8px",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "white", // Label color
                      },
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault(); // Prevent default Enter behavior
                      }
                    }}
                    // groupBy={(option) => option.division?.name || undefined}
                  />
                </Grid>
                {/* <Grid item xs={12} md={6} lg={4}></Grid> */}

                <Grid item xs={12}>
                  <MyAutocomplete
                    multiple
                    options={masterData.categories || []}
                    getOptionLabel={(op) => op.name || ""}
                    value={filterCriteria.category || []}
                    label="Category"
                    onChange={(_, v) => handleAutocompleteChange("category", v)}
                    getOptionSelected={(opt, val) => opt?._id === val?._id}
                    InputProps={{
                      style: {
                        color: "white",
                        paddingTop: "8px",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "white", // Label color
                      },
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault(); // Prevent default Enter behavior
                      }
                    }}
                    // groupBy={(option) => option.division?.name || undefined}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MyAutocomplete
                    multiple
                    options={masterData.subCategories || []}
                    getOptionLabel={(op) => op.name || ""}
                    value={filterCriteria.subCategory || []}
                    label="Sub Category"
                    onChange={(_, v) =>
                      handleAutocompleteChange("subCategory", v)
                    }
                    hideAction={{ successMessage: true }}
                    getOptionSelected={(opt, val) => opt?._id === val?._id}
                    InputProps={{
                      style: {
                        color: "white",
                        paddingTop: "8px",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "white", // Label color
                      },
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault(); // Prevent default Enter behavior
                      }
                    }}
                    // groupBy={(option) => option.division?.name || undefined}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MyAutocomplete
                    multiple
                    options={masterData.collectionLines || []}
                    getOptionLabel={(op) => op.name || ""}
                    value={filterCriteria.collectionLine || []}
                    label="Collection Line"
                    onChange={(_, v) =>
                      handleAutocompleteChange("collectionLine", v)
                    }
                    hideAction={{ successMessage: true }}
                    getOptionSelected={(opt, val) => opt?._id === val?._id}
                    InputProps={{
                      style: {
                        color: "white",
                        paddingTop: "8px",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "white", // Label color
                      },
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault(); // Prevent default Enter behavior
                      }
                    }}
                    // groupBy={(option) => option.division?.name || undefined}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="subtitle1">Net Weight</Typography>
                  <TextField
                    fullWidth
                    label="From"
                    type="number"
                    value={filterCriteria.netWeight?.from || ""}
                    onChange={handleRangeChange("netWeight", "from")}
                    variant="outlined"
                    InputProps={{
                      style: {
                        color: "white",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "white", // Label color
                      },
                    }}
                  />
                  <TextField
                    fullWidth
                    label="To"
                    type="number"
                    value={filterCriteria.netWeight?.to || ""}
                    onChange={handleRangeChange("netWeight", "to")}
                    variant="outlined"
                    InputProps={{
                      style: {
                        color: "white",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "white", // Label color
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="subtitle1">Gross Weight</Typography>
                  <TextField
                    fullWidth
                    label="From"
                    type="number"
                    value={filterCriteria.grossWeight?.from || ""}
                    onChange={handleRangeChange("grossWeight", "from")}
                    variant="outlined"
                    InputProps={{
                      style: {
                        color: "white",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "white", // Label color
                      },
                    }}
                  />
                  <TextField
                    fullWidth
                    label="To"
                    type="number"
                    value={filterCriteria.grossWeight?.to || ""}
                    onChange={handleRangeChange("grossWeight", "to")}
                    variant="outlined"
                    InputProps={{
                      style: {
                        color: "white",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "white", // Label color
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="subtitle1">Diamond CTS</Typography>
                  <TextField
                    fullWidth
                    label="From"
                    type="number"
                    value={filterCriteria.totalDiamondWeight?.from || ""}
                    onChange={handleRangeChange("totalDiamondWeight", "from")}
                    variant="outlined"
                    InputProps={{
                      style: {
                        color: "white",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "white", // Label color
                      },
                    }}
                  />
                  <TextField
                    fullWidth
                    label="To"
                    type="number"
                    value={filterCriteria.totalDiamondWeight?.to || ""}
                    onChange={handleRangeChange("totalDiamondWeight", "to")}
                    variant="outlined"
                    InputProps={{
                      style: {
                        color: "white",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "white", // Label color
                      },
                    }}
                  />
                </Grid>
                {/* <FilterAction /> avn akkan en bug oh eno..*/}
              </Grid>
            )}
          </Paper>
        </div>
      </div>
    </Drawer>
  );
};

const componentStyles = {
  resetIcon: {
    fontSize: "48px",
  },
};
export default SearchFilter;
