import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./linkitSlices/userSlice";
import cartReducer from "./linkitSlices/cartSlice";
import mainReducer from "./linkitSlices/mainSlice";
import ogFilterReducer from "./linkitSlices/ogFilterSlice";
import purities from "./linkitSlices/puritiesSlice";
import orders from "./linkitSlices/orderSlice";
import metals from "./linkitSlices/metalsSlice";
import diamonds from "./linkitSlices/diamondsSlice";
import categories from "./linkitSlices/categoriesSlice";
import subCategories from "./linkitSlices/subCategoriesSlice";
import collectionLines from "./linkitSlices/collectionLinesSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    cart: cartReducer,
    main: mainReducer,
    ogFilter: ogFilterReducer,
    purities: purities,
    orders: orders,
    metals: metals,
    diamonds: diamonds,
    categories,
    subCategories,
    collectionLines,
  },
});

export default store;
