import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SnackbarComponent from "@ui/components/SnackbarComponent";
import { SnackbarProvider } from "notistack";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import Loader from "./components/Loader";
import Layout from "./views/ExpiryLinks/Detail/components/Layout";
import { primaryMenuRoutes, secondaryMenuRoutes } from "./routes";
import typographyTheme from "./@ui/assets/theme/typographyTheme";
import InternetConnectionChecker from "@ui/Actions/InternetConnection/InternetConnectionChecker";
import UrlExpired from "views/ExpiryLinks/Detail/components/UrlExpired";
import LoginWithOTP from "views/ExpiryLinks/Detail/components/LoginWithOTP";
import { useSelector } from "react-redux";

const theme = createTheme({
  typography: {
    typographyTheme,
    fontFamily: "Lato, Arial, sans-serif",
  },
  direction: "ltr",
});

const GlobalStyle = createGlobalStyle`
   body {
  font-family: 'Lato', sans-serif; 
  min-height: 100vh;
  overflow: hidden;
  }
`;

function AuthenticatedRoute({ children }) {
  const userDetails = useSelector((state) => state?.user); // Access user state from Redux

  if (!userDetails || !userDetails._id) {
    const currentCode = window.location.pathname.split("/")[1]; // Extract code from URL
    return <LoginWithOTP code={currentCode} />; // Show login when userDetails is missing
  }

  return children;
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <SnackbarComponent />
        <GlobalStyle />
        <Loader />

        <BrowserRouter>
          <InternetConnectionChecker />
          <Routes>
            {/* Authenticated Routes */}
            <Route
              path=":code"
              element={
                <AuthenticatedRoute>
                  <Layout />
                </AuthenticatedRoute>
              }
            >
              {primaryMenuRoutes
                .filter((route) => route.access) // Filter routes with access
                .map((route, i) => (
                  <Route
                    key={i}
                    path={route.path}
                    element={<route.component />} // Render component directly
                  />
                ))}

              {secondaryMenuRoutes &&
                secondaryMenuRoutes.map((route, i) => (
                  <Route
                    key={i}
                    path={route.path}
                    element={<route.component />}
                  />
                ))}
            </Route>

            {/* Fallback for Invalid Routes */}
            <Route path="*" element={<UrlExpired />} />
          </Routes>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
