import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { search } from "@ui/ComponentUtils/blueprintAPIsWithoutLoading";
import commonEnums from "@ui/Utils/commonEnums";
import { filterUniqueByName } from "@ui/Utils/helper";

const initialState = []; // Store subCategories data

export const fetchSubCategories = createAsyncThunk(
  "subCategories/fetchSubCategories",
  async ({}, { rejectWithValue }) => {
    try {
      const subCategoriesList = await search(
        commonEnums.models["data-engine"].subCategory,
        {
          filterColumns: { name: 1, _id: 1, division: 1 },
          //   not handled for subCat yet... #22Dec2024_9:54pm
          //   catalogues: { $in: allowedCatalogues },
        }
      );

      return filterUniqueByName(subCategoriesList) || [];
    } catch (error) {
      console.error("Error fetching subCategories:", error);
      return rejectWithValue(error.message);
    }
  }
);

const subCategoriesSlice = createSlice({
  name: "subCategories",
  initialState,
  reducers: {
    clearSubCategories: () => initialState, // Reset to empty array
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSubCategories.fulfilled, (state, action) => {
      return action.payload; // Replace state with fetched subCategories data
    });
  },
});

export const { clearSubCategories } = subCategoriesSlice.actions;
export default subCategoriesSlice.reducer;
