import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { search } from "@ui/ComponentUtils/blueprintAPIsWithoutLoading";
import commonEnums from "@ui/Utils/commonEnums";
import { filterUniqueByName } from "@ui/Utils/helper";

const initialState = []; // Store collectionLines data

export const fetchCollectionLines = createAsyncThunk(
  "collectionLines/fetchCollectionLines",
  async ({ allowedCatalogues }, { rejectWithValue }) => {
    try {
      const collectionLinesList = await search(
        commonEnums.models["data-engine"].collectionLines,
        {
          filterColumns: { name: 1, _id: 1, division: 1 },
          catalogues: { $in: allowedCatalogues },
        }
      );

      return filterUniqueByName(collectionLinesList) || [];
    } catch (error) {
      console.error("Error fetching collectionLines:", error);
      return rejectWithValue(error.message);
    }
  }
);

const collectionLinesSlice = createSlice({
  name: "collectionLines",
  initialState,
  reducers: {
    clearCollectionLines: () => initialState, // Reset to empty array
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCollectionLines.fulfilled, (state, action) => {
      return action.payload; // Replace state with fetched collectionLines data
    });
  },
});

export const { clearCollectionLines } = collectionLinesSlice.actions;
export default collectionLinesSlice.reducer;
