// Layout.js
import React, { useEffect } from "react";
import { Toolbar, Typography } from "@mui/material";
import AppBarComponent from "./AppBarComponent";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearCart, initializeCart } from "redux/linkitSlices/cartSlice";
import { clearProducts, fetchProducts } from "redux/linkitSlices/mainSlice";
import {
  clearPurities,
  initializePurities,
} from "redux/linkitSlices/puritiesSlice";
import { getLinkItProductsMongooseCriteria } from "./SearchFilter";
import enums from "helpers/enums";
import { clearOrders, fetchOrders } from "redux/linkitSlices/orderSlice";
import { clearMetals, fetchGoldPricing } from "redux/linkitSlices/metalsSlice";
import {
  clearDiamonds,
  fetchDiamondPricing,
} from "redux/linkitSlices/diamondsSlice";
import { clearOGfilter, setOGfilter } from "redux/linkitSlices/ogFilterSlice";
import { clearCategories } from "redux/linkitSlices/categoriesSlice";
import { clearSubCategories } from "redux/linkitSlices/subCategoriesSlice";
import { clearCollectionLines } from "redux/linkitSlices/collectionLinesSlice";

const Layout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      let lastCriteria = sessionStorage.getItem(
        enums.sessionStorage.lastCriteria
      );
      lastCriteria = lastCriteria ? JSON.parse(lastCriteria) : {};

      dispatch(initializeCart({}));

      dispatch(setOGfilter(lastCriteria));
      dispatch(initializePurities());
      //   const body = {
      //     page: 1,
      //   };

      //   let filterCriteria = sessionStorage.getItem(
      //     enums.sessionStorage.lastCriteria
      //   );
      //   filterCriteria = filterCriteria ? JSON.parse(filterCriteria) : null;
      //   if (filterCriteria) {
      //     body.productsCriteria =
      //       getLinkItProductsMongooseCriteria(filterCriteria);
      //   }

      //   dispatch(fetchProducts(body));

      dispatch(fetchOrders());
      dispatch(fetchDiamondPricing());
      dispatch(fetchGoldPricing());
    } catch (err) {
      console.log(err);
    }

    return () => {
      dispatch(clearOGfilter());
      dispatch(clearCart());
      dispatch(clearPurities());
      dispatch(clearOrders());
      dispatch(clearProducts());
      dispatch(clearMetals());
      dispatch(clearDiamonds());
      dispatch(clearCategories());
      dispatch(clearSubCategories());
      dispatch(clearCollectionLines());
    };
  }, [dispatch]);

  return (
    <>
      <AppBarComponent position="static">
        <Toolbar>
          <Typography variant="h6">My App</Typography>
        </Toolbar>
      </AppBarComponent>
      <div
        style={
          {
            // marginTop: "64px", padding: "16px"
          }
        }
      >
        <Outlet /> {/* Placeholder for child routes */}
      </div>
    </>
  );
};

export default Layout;
