import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { searchWithHook } from "@ui/ComponentUtils/blueprintAPIsWithoutLoading";
import enums from "helpers/enums";

const initialState = []; // Directly store the array of orders

// Async thunk to fetch orders
export const fetchOrders = createAsyncThunk(
  "orders/fetchOrders",
  async (_, { rejectWithValue }) => {
    try {
      const orders = await searchWithHook(enums.models.linkItOrders, {
        filterColumns: {
          orderId: 1,
          dateCreated: 1,
          "items.orderStatus": 1,
          overallRemarks: 1,
          "items.grossWeight": 1,
          "items.quantity": 1,
          "items.product.grossWeight": 1,
          "items.product.netWeight": 1,
          "items.product.totalDiamondWeight": 1,
          "items.product.jewelPriceBreakup": 1,
        },
        sort: { _id: -1 },
      });

      return orders?.records || []; // Return the fetched records or an empty array
    } catch (error) {
      console.error("Error fetching orders:", error);
      return rejectWithValue(error.message);
    }
  }
);

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    clearOrders: () => initialState, // Reset state to an empty array
    unshiftOrder: (state, action) => {
      state.unshift(action.payload); // Adds the new order at the start of the array
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrders.fulfilled, (state, action) => {
      return action.payload; // Replace the state with the fetched orders
    });
  },
});

export const { clearOrders, unshiftOrder } = ordersSlice.actions;
export default ordersSlice.reducer;
