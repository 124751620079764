import React, { useState, useEffect, useRef } from "react";
import { TextField, Box, Button, Grid } from "@mui/material";
import { sendOTP, verifyOTP } from "@ui/Auth/api";
import Emitter from "@ui/Utils/CustomEventEmitter";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "redux/linkitSlices/userSlice";
import "./LoginWithOTP.css"; // Import CSS file for custom styles
import enums from "helpers/enums";
const eachItemDelay = 0.2;

export const sessionKeys = {
  userDetails: "userDetails",
  token: "token",
};

const LoginWithOTP = () => {
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [timer, setTimer] = useState(0);
  const [status, setStatus] = useState(""); // 'success', 'error', or ''
  const [isUnlocked, setIsUnlocked] = useState(false);
  const otpInputRef = useRef(null);
  const dispatch = useDispatch();
  const shortCode = useParams()?.code;
  const shortCriteria = {
    expirationDate: { $gt: new Date() },
  };

  useEffect(() => {
    let interval;
    if (otpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [otpSent, timer]);

  useEffect(() => {
    if (otpSent) {
      otpInputRef.current.focus();
    }
  }, [otpSent, otpInputRef]);

  const onSendOTP = async () => {
    try {
      if (phone.length !== 10) {
        setStatus("error");
        Emitter.emit("alert_error", "Invalid Phone");
        return;
      }

      const res = await sendOTP({
        phone,
        sentOTPorderId: otpSent,
        appModule: "data-engine",
        throwIfUserDoesNotExist: true,
        shortCode,
        shortCodeModel: enums.models.expiryLinks,
        shortCriteria: { ...shortCriteria, "validUsers.phone": phone },
      });
      setStatus("success");
      setTimer(60); // Set the timer to 60 seconds as OTPLess also is 1 min by default not allowing resend
      setOtpSent(res?.otpSession);
      Emitter.emit("alert_success", "OTP sent successfully");
    } catch (err) {
      setStatus("error");
      setOtp("");

      if (err?.response?.data?.message?.match("3 times")) {
        setPhone("");
        setOtpSent();
        return Emitter.emit(
          "alert_error",
          "Phone number reset due to 3 retries limit"
        );
      }
      Emitter.emit(
        "alert_error",
        err?.response?.data?.message || "Try again ⚠️"
      );
    }
  };

  const onSubmit = async () => {
    try {
      const res = await verifyOTP({
        phone,
        otp,
        sentOTPorderId: otpSent,
        attachToken: true,
        appModule: "data-engine",
        shortCode,
        shortCodeModel: enums.models.expiryLinks,
        shortCriteria: { ...shortCriteria, "validUsers.phone": phone },
      });

      setStatus("success");
      setIsUnlocked(true);

      sessionStorage.setItem(
        sessionKeys.token,
        JSON.stringify(res.data.data.token)
      );

      // Dispatch the setUser action to update Redux
      dispatch(setUser(res.data.data.user));
    } catch (err) {
      setStatus("error");
      setOtp("");
      Emitter.emit(
        "alert_error",
        err?.response?.data?.message || "Access FAILED ⚠️"
      );
    }
  };

  useEffect(() => {
    if (phone.length === 10) {
      onSendOTP();
    } else if (otpSent && phone.length < 10) {
      setOtpSent(false);
      setTimer(0);
    }
  }, [phone]);

  useEffect(() => {
    if (otp.length === 4) {
      onSubmit();
    }
  }, [otp]);

  return (
    <div className={`container ${status}`}>
      <pre
        style={{
          marginTop: "-30px",
          marginBottom: "20px",
          fontSize: isUnlocked ? "26px" : "42px",
          textAlign: "center",
          letterSpacing: isUnlocked ? "1px" : "2px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        Login
      </pre>
      {isUnlocked ? null : (
        <Box
          component="form"
          sx={{
            width: "100%",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="phone"
            label="Phone"
            name="phone"
            autoComplete="tel"
            autoFocus
            type="tel"
            value={phone}
            onChange={(e) => {
              if (e.target.value?.toString()?.trim()?.length <= 10) {
                setPhone(
                  e.target.value
                    ?.toString()
                    .trim()
                    .replace(/[^0-9]/g, "")
                    .replace(/\s/g, "")
                );
              }
            }}
            onFocus={(e) =>
              e.target.addEventListener("wheel", (e) => e.preventDefault(), {
                passive: false,
              })
            }
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.preventDefault(); // Prevent form submission on Enter key press
              }
            }}
            InputProps={{
              inputProps: {
                // max: 10,
                style: {
                  fontWeight: "bold",
                  letterSpacing: "1px",
                  fontSize: "24px",
                  fontFamily: "Courier, monospace",
                  textAlign: "center",
                  MozAppearance: "textfield", // Firefox
                },
              },
              sx: {
                "& input[type=number]": {
                  MozAppearance: "textfield", // Firefox
                },
                "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none", // Chrome, Safari, Edge, Opera
                    margin: 0,
                  },
              },
            }}
            className="input-field"
          />

          {!!otpSent && (
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="otp"
              label="OTP"
              name="otp"
              placeholder="* * * *"
              autoComplete="one-time-code"
              value={otp}
              type="tel"
              onChange={(e) =>
                e.target.value?.toString()?.length <= 4
                  ? setOtp(e.target.value)
                  : null
              }
              inputRef={otpInputRef}
              InputProps={{
                inputProps: {
                  max: 4,
                  style: {
                    fontWeight: "bold",
                    letterSpacing: "1px",
                    fontSize: "24px",
                    fontFamily: "Courier, monospace",
                    textAlign: "center",
                    MozAppearance: "textfield", // Firefox
                  },
                },
                sx: {
                  "& input[type=number]": {
                    MozAppearance: "textfield", // Firefox
                  },
                  "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none", // Chrome, Safari, Edge, Opera
                      margin: 0,
                    },
                },
              }}
              className="input-field"
            />
          )}

          <center
            style={{
              animation: `fadeIn ${eachItemDelay}s forwards ${2}s`, //dynamicDelayForExtraButtons
              marginTop: "24px",
            }}
          >
            {otpSent && timer > 0 ? (
              <Button
                variant="contained"
                disabled={true}
                sx={{
                  width: "60px",
                  height: "60px",
                  margin: "5px",
                  borderRadius: "10px",
                  fontSize: "1.5rem",
                  backgroundColor: "#6b6b6b",
                  color: "#fff",
                  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
                }}
              >
                {`${timer}`}
              </Button>
            ) : (
              otpSent && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onSendOTP}
                  sx={{
                    // height: "60px",
                    margin: "5px",
                    borderRadius: "10px",
                    // fontSize: "1.5rem",/
                    color: "#fff",
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
                    textTransform: "none",
                  }}
                >
                  Resend OTP
                </Button>
              )
            )}
          </center>
        </Box>
      )}
    </div>
  );
};

export default LoginWithOTP;
