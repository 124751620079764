import commonEnums from "@ui/Utils/commonEnums";
import { useDispatch, useSelector } from "react-redux";
import { syncCartWithDB, updateCartItems } from "redux/linkitSlices/cartSlice";

const isCustomizationMatch = (item, customization) => {
  if (
    !customization ||
    Object.values(customization).filter((v) => v).length === 0
  ) {
    return true; // No customization to match
  }

  return Object.keys(customization).every((key) => {
    switch (key) {
      case "purity":
        return item?.purity?.value === customization?.purity?.value;

      case "diamondDetails":
        return (
          (customization.diamondDetails?.[0]?.color
            ? customization.diamondDetails[0]?.color ===
              item.diamondDetails?.[0]?.color
            : true) &&
          (customization.diamondDetails?.[0]?.clarity
            ? customization.diamondDetails[0].clarity ===
              item.diamondDetails[0].clarity
            : true)
        );

      case "product":
      case "jewelPrice":
      case "jewelPriceBreakup":
        return true;
    }
    return (
      item[key]?.toString().trim() === customization[key]?.toString().trim()
    );
  });
};

const useCartActions = () => {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.cart.items);

  const handleAddToCart = async (cartItem) => {
    try {
      const customization = {
        purity: cartItem.purity,
        color: cartItem.color,
        remarks: cartItem.remarks?.toString().trim(),
        size: cartItem.size,
        screw: cartItem.screw?.toString().trim(),
        setting: cartItem.setting?.toString().trim(),
        grossWeight: cartItem.grossWeight,
        specificEngraving: cartItem.specificEngraving?.toString().trim(),
        diamondDetails: cartItem.diamondDetails,
      };

      // Directly update items array
      let updatingIndex = items.findIndex((item) => {
        return (
          item.product._id === cartItem.product._id &&
          isCustomizationMatch(item, customization)
        );
      });

      const updatedItems = [...items];

      if (updatingIndex === -1) {
        updatedItems.push({
          ...cartItem,
          product: cartItem.product,
          quantity: cartItem.quantity || 1,
          division: cartItem.product.division?._id
            ? cartItem.product.division
            : undefined,

          isCustomized: Object.values(customization).filter((v) => v).length
            ? commonEnums.itemCustomization.product
            : undefined,
        });

        updatingIndex = updatedItems.length - 1;
      } else {
        // Update existing item

        updatedItems[updatingIndex] = {
          ...updatedItems[updatingIndex],
          quantity:
            updatedItems[updatingIndex].quantity + (cartItem.quantity || 1),
        };

        // Update customization
        if (Object.values(customization).filter((v) => v).length) {
          updatedItems[updatingIndex].isCustomized = Object.values(
            customization
          ).filter((v) => v).length
            ? commonEnums.itemCustomization.product
            : undefined;
        }
      }

      // Update the Redux state
      //   dispatch(updateCartItems(updatedItems));

      // Sync with the database
      dispatch(syncCartWithDB({ items: updatedItems, updatingIndex }));
    } catch (err) {
      console.error("Error adding to cart:", err);
    }
  };

  const handleRemoveFromCart = async (cartItem) => {
    try {
      const customization = {
        purity: cartItem.purity,
        color: cartItem.color,
        remarks: cartItem.remarks?.toString().trim(),
        size: cartItem.size,
        screw: cartItem.screw?.toString().trim(),
        setting: cartItem.setting?.toString().trim(),
        grossWeight: cartItem.grossWeight,
        specificEngraving: cartItem.specificEngraving?.toString().trim(),
        diamondDetails: cartItem.diamondDetails,
      };

      let cartItems = [...items]; // Create a copy of the cart to modify

      // Find the index of the item to be removed
      const itemIndex = cartItems.findIndex((item) => {
        return (
          item.product?._id === cartItem.product?._id &&
          isCustomizationMatch(item, customization)
        );
      });

      if (itemIndex !== -1) {
        // Remove the item at the found index
        cartItems.splice(itemIndex, 1);
      }

      // Update the Redux state
      //   dispatch(updateCartItems(cartItems));

      // Sync with the database
      dispatch(syncCartWithDB({ items: cartItems }));
    } catch (err) {
      console.error("Error removing from cart:", err);
    }
  };

  return {
    handleAddToCart,
    handleRemoveFromCart,
  };
};

export default useCartActions;
