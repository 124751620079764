import { createSlice } from "@reduxjs/toolkit";
import enums from "helpers/enums";

const initialState = {};

const ogFilterSlice = createSlice({
  name: "ogFilter",
  initialState,
  reducers: {
    setOGfilter: (state, action) => {
      sessionStorage.setItem(
        enums.sessionStorage.lastCriteria,
        JSON.stringify(action.payload)
      );

      return { ...state, ...action.payload };
    },
    clearOGfilter: () => {
      sessionStorage.removeItem(enums.sessionStorage.lastCriteria);

      return initialState;
    },
  },
});

export const { setOGfilter, clearOGfilter } = ogFilterSlice.actions;
export default ogFilterSlice.reducer;
