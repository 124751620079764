import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { staticViews } from "./AppBarComponent";
import { useParams } from "react-router-dom/dist/index";
import UrlExpired from "./UrlExpired";
import ScrollTopButton from "@ui/Actions/Scroll/ScrollTopButton";

import Emitter from "@ui/Utils/CustomEventEmitter";
import { getLinkItProductsMongooseCriteria } from "./SearchFilter";
import { MyCarousel } from "@ui/MuiComponents/index";
import LINKitTitle from "./LINKitTitle";
import { createGlobalStyle } from "styled-components";
import ProductList from "./ProductList";
import { useDispatch, useSelector } from "react-redux";
import {
  clearProducts,
  fetchProducts,
  setPage,
} from "redux/linkitSlices/mainSlice";
import enums from "helpers/enums";

export const handleQuantityChange = (event, setQuantity) => {
  let value = parseInt(event.target.value || 0);
  if (value > 999) {
    return Emitter.emit("alert_error", "Maximum Quantity Allowed: 999");
  }
  // else if (value < 0) {
  //   value = 0;
  // }

  if (/^\d*$/.test(value)) {
    setQuantity(value || "");
  }
};

const GlobalStyle = createGlobalStyle`
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body {
    font-family: 'Lato', sans-serif;
  }

  /* Ensure all components and elements inherit this font */
  body, button, input, textarea, select {
    font-family: 'Lato', sans-serif !important;
  }
 
`;

const ECommerce = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const main = useSelector((state) => state.main) || {};
  const paginationInfo = main.paginationInfo,
    page = main.page;

  const [expiredLink, setExpiredLink] = useState(false);
  const [gridView, setGridView] = useState(false);
  const [preview, setPreview] = useState([]);

  const [showFilter, setShowFilter] = useState(false);

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Check for mobile view

  const params = useParams();

  // const { ref, inView } = useInView({
  //   threshold: 1.0,
  // });

  // const [isLoaded, setIsLoaded] = useState(false);

  // useEffect(() => {
  //   // Simulate heavy loading or data fetching
  //   const timer = setTimeout(() => {
  //     setIsLoaded(true);
  //   }, 10); // Simulate a delay (1 second)

  //   return () => clearTimeout(timer);
  // }, []);

  useEffect(() => {
    (async () => {
      if (!paginationInfo.currentPageNo) {
        const body = {
          page: 1,
        };

        let filterCriteria = sessionStorage.getItem(
          enums.sessionStorage.lastCriteria
        );
        filterCriteria = filterCriteria ? JSON.parse(filterCriteria) : null;
        if (filterCriteria) {
          body.productsCriteria =
            getLinkItProductsMongooseCriteria(filterCriteria);
        }

        await dispatch(fetchProducts(body));
      }
    })();

    return () => dispatch(clearProducts());
  }, []);

  // useEffect(() => {
  //   if (inView) {
  //     fetchNextPage();
  //   }
  // }, [params.code, page, inView]);

  const fetchNextPage = () => {
    if (
      // inView &&
      params.code &&
      paginationInfo.totalPages !== paginationInfo.currentPageNo
    ) {
      const localSessionProductFilter = sessionStorage.getItem(
        enums.sessionStorage.lastCriteria
      );
      let productsCriteria;
      const nextPage = page + 1;

      if (
        !Object.values(productsCriteria || {}).find((val) => val) &&
        localSessionProductFilter
      ) {
        productsCriteria = JSON.parse(localSessionProductFilter);

        dispatch(
          fetchProducts({
            page: nextPage,
            productsCriteria:
              getLinkItProductsMongooseCriteria(productsCriteria),
          })
        );
      } else {
        dispatch(setPage(nextPage));

        dispatch(
          fetchProducts({
            page: nextPage,
          })
        );
      }
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  // const handleSelect = (product) => {
  //   setSelected((prevSelected) =>
  //     prevSelected.includes(product._id)
  //       ? prevSelected.filter((id) => id !== product._id)
  //       : [...prevSelected, product._id]
  //   );
  // };

  if (expiredLink) {
    return (
      <div>
        <UrlExpired />
      </div>
    );
  }

  return (
    <div>
      <GlobalStyle />
      <main
        style={{
          paddingBottom: "60px",
        }}
      >
        {/* Add bottom padding */}

        <LINKitTitle
          paginationInfo={paginationInfo}
          staticViews={staticViews}
          user={user}
        />

        <div>
          <ProductList
            isMobile={isMobile}
            paginationInfo={paginationInfo}
            gridView={gridView}
            setShowFilter={setShowFilter}
            showFilter={showFilter}
          />

          {/* <pre
            // ref={ref}
            style={{
              position: "relative",
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
              fontSize: "20px",
              fontWeight: "bold",
              color: "#333",
              backgroundColor: "#f0f0f0",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              padding: "20px",
              marginBottom: "250px",
            }}
          >
            {paginationInfo.totalPages !== paginationInfo.currentPageNo ? (
              <div>
                {
                  "loading more..."
                  // // diamond?
                }
              </div>
            ) : (
              "End of Catalogue..." // onClick, whatsapp of client whatsapp number to say if need more products, contact... // not good idea because it will be seen after every filterred results... :(
            )}
          </pre> */}
        </div>
        <ScrollTopButton style={{ bottom: "84px" }} />
        {preview.length ? (
          <MyCarousel
            items={preview}
            setOpenPreview={() => setPreview([])}
            openPreview={true}
            hideAction={{ download: true }}
          />
        ) : null}
      </main>
    </div>
  );
};

export default ECommerce;
