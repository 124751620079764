import React from "react";
import { Typography, Grid } from "@mui/material";
import enums from "helpers/enums";
import { formatWeight } from "@ui/Utils/helper";

function LINKitTitle({ paginationInfo, user }) {
  return (
    <Grid
      container
      style={{
        paddingLeft: "16px",
        paddingRight: "16px",
        userSelect: "none",
        position: "fixed",
        top: 5, // Fix it to the top
        left: 0,
        width: "100%", // Ensure it spans the full width
        zIndex: 1000,
        height: "48px",
      }}
    >
      {/* Left Section: Client Name and Stats */}
      <Grid
        item
        xs={6}
        sm={6}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h4"
          style={{
            fontFamily: "Roboto",
            fontSize: "18px",
            fontWeight: 400,
            lineHeight: "24px",
            textAlign: "left",
            color: enums.colorTheme.secondary,
            letterSpacing: "0.5px",
          }}
        >
          {user.client?.name}
        </Typography>
        <Typography
          variant="subtitle1"
          style={{
            fontStyle: "italic",
            color: enums.colorTheme.secondary,
            fontSize: "10px",
            fontWeight: 300,
            marginTop: "8px",
            textAlign: "left",
          }}
        >
          {
            // loading === "loading" ? (
            // "Just a sec..."
            // ) :
            !paginationInfo?.count ? (
              "No Product Found"
            ) : (
              <>
                {paginationInfo.count}{" "}
                {paginationInfo.count > 1 ? "Products" : "Product"},{" "}
                {formatWeight(paginationInfo.totalGrossWeight)}
                {paginationInfo.totalDiamondWeight
                  ? `, ${formatWeight(paginationInfo.totalDiamondWeight, {
                      inputInCts: true,
                    })}`
                  : ""}
              </>
            )
          }
        </Typography>
      </Grid>

      {/* Right Section: Price */}
      <Grid
        item
        xs={6}
        sm={6}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {paginationInfo?.liveGoldPrice && (
          <Typography
            variant="h4"
            style={{
              fontFamily: "Roboto",
              fontSize: "20px",
              fontWeight: "bold",
              lineHeight: "24px",
              textAlign: "center",
              letterSpacing: "0.5px",
              background:
                "linear-gradient(rgb(200, 238, 200), rgba(253, 222, 154, 0))",
              color: "green",
              padding: "8px 16px",
              borderRadius: "5px",
            }}
          >
            ₹ {paginationInfo?.liveGoldPrice}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

export default LINKitTitle;
