import { createSlice } from "@reduxjs/toolkit";

export const sessionKeys = {
  userDetails: "userDetails",
};

// Helper function to get initial state from sessionStorage
const getInitialState = () => {
  const userDetails = sessionStorage.getItem(sessionKeys.userDetails);
  return userDetails ? JSON.parse(userDetails) : {}; // Parse session data or return empty object
};

const initialState = getInitialState();

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      const updatedUser = { ...state, ...action.payload };
      // Save the updated user to sessionStorage
      sessionStorage.setItem(
        sessionKeys.userDetails,
        JSON.stringify(updatedUser)
      );
      return updatedUser;
    },
    clearUser: () => {
      // Remove user from sessionStorage
      sessionStorage.removeItem(sessionKeys.userDetails);
      return {};
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
