import HTTP from "@ui/Utils/http";
import HTTPWithoutLoading from "@ui/Utils/httpWithoutLoading";
import commonConfig from "config/commonConfig";
import enums from "helpers/enums";

export const fetchValidProducts = async ({
  page = 1,
  pageSize = 10,
  sort = { _id: -1, styleNo: 1 },
  criteria = { shortCode: "" },
} = {}) => {
  try {
    // Works with Catalogues Criteria to match with requested user's access to his catalogue allowed products.
    const params = {
      pageNo: page,
      perPageRecords: pageSize || 10,
      criteria: criteria,
      sort: sort,
    };

    const url =
      commonConfig.baseApiUrl +
      "c/" +
      enums.models.expiryLinks +
      "/fetchValidProducts";

    let res = {};

    // if (page === 1) {
    res = await HTTP.post(url, params);
    // } else {
    //   res = await HTTPWithoutLoading.post(url, params);
    // }

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
