import React from "react";
import { Typography, Grid, useTheme, useMediaQuery } from "@mui/material";
import { formatIndianNumber, roundOff } from "@ui/Utils/helper";
import { TextField } from "../../../../../../node_modules/@material-ui/core/index";

const CartHeader = ({ cart, overallRemarks, setOverallRemarks }) => {
  // Calculate total statistics
  const stats = {
    totalQuantity: 0,
    totalGrossWeight: 0,
    totalNetWeight: 0,
    totalDiamondWeight: 0,
    totalJewelPrice: 0,
  };

  (cart || []).map((item) => {
    stats.totalQuantity += item.quantity;

    stats.totalGrossWeight += item.product?.grossWeight * item.quantity;

    stats.totalNetWeight +=
      (item.product?.jewelPriceBreakup?.appliedNetWeight ||
        item.product?.netWeight) * item.quantity;

    stats.totalDiamondWeight +=
      (item.product?.totalDiamondWeight || 0) * item.quantity;

    stats.totalJewelPrice +=
      (item.jewelPrice || item.product?.jewelPrice || 0) * item.quantity;
  });

  // Theme and breakpoints for responsiveness
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      {/* Cart Title */}

      {/* Cart Stats */}
      <Grid
        container
        spacing={2}
        style={{
          padding: "16px",
          backgroundColor: "#fff", // White background for the stats section
          borderRadius: "8px",
          marginTop: "10px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for a premium feel
        }}
      >
        <Grid item xs={12} lg={2}>
          <Typography
            variant={isMobile ? "h5" : "h4"}
            gutterBottom
            style={{
              fontWeight: "bold",
              color: "#B8860B", // Gold color for title
            }}
          >
            Cart
          </Typography>
        </Grid>

        {/* Total Gross Weight */}
        {stats.totalGrossWeight ? (
          <Grid item xs={6} lg={2}>
            <Typography
              variant={isMobile ? "body1" : "h6"}
              style={componentStyles.label}
            >
              Gross Weight:
            </Typography>
            <Typography
              variant={isMobile ? "h6" : "h5"}
              style={componentStyles.value}
            >
              {roundOff(stats.totalGrossWeight, { weight: true })} g
            </Typography>
          </Grid>
        ) : null}

        {/* Total Net Weight */}
        {stats.totalNetWeight ? (
          <Grid item xs={6} lg={2}>
            <Typography
              variant={isMobile ? "body1" : "h6"}
              style={componentStyles.label}
            >
              Net Weight:
            </Typography>
            <Typography
              variant={isMobile ? "h6" : "h5"}
              style={componentStyles.value}
            >
              {roundOff(stats.totalNetWeight, { weight: true })} g
            </Typography>
          </Grid>
        ) : null}

        {stats.totalDiamondWeight ? (
          <Grid item xs={6} lg={2}>
            <Typography
              variant={isMobile ? "body1" : "h6"}
              style={componentStyles.label}
            >
              Dia:
            </Typography>
            <Typography
              variant={isMobile ? "h6" : "h5"}
              style={componentStyles.value}
            >
              {roundOff(stats.totalDiamondWeight, {
                weight: true,
                isDiamond: true,
              })}{" "}
              cts
            </Typography>
          </Grid>
        ) : null}

        {/* Total Quantity */}
        {stats.totalQuantity ? (
          <Grid item xs={6} lg={2}>
            <Typography
              variant={isMobile ? "body1" : "h6"}
              style={componentStyles.label}
            >
              Quantity:
            </Typography>
            <Typography
              variant={isMobile ? "h6" : "h5"}
              style={componentStyles.value}
            >
              {stats.totalQuantity}
            </Typography>
          </Grid>
        ) : null}

        {/* Total Jewel Price */}
        <Grid item xs={6} lg={2}>
          {stats.totalJewelPrice > 0 && (
            <>
              <Typography
                variant={isMobile ? "body1" : "h6"}
                style={componentStyles.label}
              >
                Total Price:
              </Typography>
              <Typography
                variant={isMobile ? "h6" : "h5"}
                style={{ ...componentStyles.value, color: "#B8860B" }} // Gold color for price
              >
                ₹ {formatIndianNumber(roundOff(stats.totalJewelPrice))}
              </Typography>
            </>
          )}
        </Grid>

        <div style={{ width: "100%", padding: "8px", marginTop: "12px" }}>
          <TextField
            value={overallRemarks || ""}
            onChange={(e) => setOverallRemarks(e.target.value)}
            label="Overall Remarks"
            multiline
            rows={3}
            InputProps={{
              style: {
                padding: "8px",
              },
            }}
            variant="outlined"
            style={{
              width: "100%",
              // maxHeight: "20px",
            }}
          />
        </div>
      </Grid>
    </div>
  );
};

const componentStyles = {
  label: { color: "#555", fontSize: "14px" },
  value: { fontWeight: "bold", fontSize: "16px", color: "#333" },
};
export default CartHeader;
