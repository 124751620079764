import React, { useEffect } from "react";
import LoginWithOTP from "./components/LoginWithOTP";
import ECommerce from "./components/ECommerce";
import Emitter from "@ui/Utils/CustomEventEmitter";
import "./detail.css";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../../../redux/linkitSlices/userSlice";

const ExpiryLinkDetail = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    const sessionUser = sessionStorage.getItem("userDetails");
    if (sessionUser && sessionUser !== "undefined") {
      dispatch(setUser(JSON.parse(sessionUser)));
    }
  }, [dispatch]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (
        e.key === "PrintScreen" ||
        (e.ctrlKey && e.key === "PrintScreen") ||
        (e.metaKey && e.shiftKey && (e.key === "3" || e.key === "4"))
      ) {
        e.preventDefault();
        Emitter.emit("alert_error", "Screenshots are disabled");
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  if (user?._id) {
    return <ECommerce user={user} />;
  }

  return <LoginWithOTP />;
};

export default ExpiryLinkDetail;
