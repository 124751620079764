import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { search } from "@ui/ComponentUtils/blueprintAPIsWithoutLoading";
import commonEnums from "@ui/Utils/commonEnums";
import { filterUniqueByName } from "@ui/Utils/helper";

const initialState = []; // Store categories data

export const fetchCategories = createAsyncThunk(
  "categories/fetchCategories",
  async ({ allowedCatalogues }, { rejectWithValue }) => {
    try {
      const categoriesList = await search(
        commonEnums.models["data-engine"].category,
        {
          filterColumns: { name: 1, _id: 1, division: 1 },
          catalogues: { $in: allowedCatalogues },
        }
      );

      return filterUniqueByName(categoriesList) || [];
    } catch (error) {
      console.error("Error fetching categories:", error);
      return rejectWithValue(error.message);
    }
  }
);

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    clearCategories: () => initialState, // Reset to empty array
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      return action.payload; // Replace state with fetched categories data
    });
  },
});

export const { clearCategories } = categoriesSlice.actions;
export default categoriesSlice.reducer;
